import { Route, Switch } from "react-router-dom";
import { StatusBanner } from "@smartrent/ui";
import { ExclamationCircleOutline } from "@smartrent/icons";

export function UnauthenticatedRouter() {
  return (
    <Switch>
      <Route
        exact
        path="/logged-out"
        render={() => (
          <StatusBanner
            status="knowledge"
            icon={ExclamationCircleOutline}
            title="You must be logged in to use SmartRent App Manager"
          />
        )}
      />
    </Switch>
  );
}
